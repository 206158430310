import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
Vue.prototype.$store = store
import ElementUI from 'element-ui'
Vue.use(ElementUI)
import 'element-ui/lib/theme-chalk/index.css'
import './assets/stylus/index.styl'
import api from '@/utils/api.js'
Vue.prototype.$api = api
import 'lib-flexible'
if (process.env.NODE_ENV == "development") {
  Vue.prototype.baseUrl = 'https://www.qdzhlawfirm.com'
  Vue.prototype.baseUrlImg = 'https://www.qdzhlawfirm.com'
} else {
  Vue.prototype.baseUrl = process.env.VUE_APP_BASE_API
  Vue.prototype.baseUrlImg = new URL(window.location.href).origin
}

router.afterEach((to,from, next) => {
  window.scrollTo(0,0)
})
Vue.config.productionTip = false
import pagination from '@/components/pagination'
Vue.component('pagination',pagination)
import modularMenu from '@/components/modularMenu'
Vue.component('modularMenu',modularMenu)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
