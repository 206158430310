<!--
 * @Description: 一入编程深似海,佛祖保佑,永无bug
 * @Author: 独孤求败
 * @Date: 2021-12-06 14:00:54
 * @LastEditTime: 2021-12-07 10:00:50
 * @LastEditors: 独孤求败
 * @Reference: 纵横江湖三十馀载，杀尽仇寇，败尽英雄，天下更无抗手，无可奈何，惟隐居深谷，以雕为友。 呜呼！生平求一敌手而不可得，诚寂寥难堪也！！！
-->
<template>
  <div class="home">
    <el-carousel height='700px'>
      <el-carousel-item v-for="item in bannerList" :key="item.id">
        <!-- <img :src="baseUrl+'/image?fileName='+item.photo" class="banner" alt=""> -->
        <el-image :src="baseUrlImg+'/image?fileName='+item.photo" fit='cover' class="img" style="width: 100%; height: 700px"></el-image>
      </el-carousel-item>
    </el-carousel>
    <div class="main_content">
      <div class="flex_start">
        <div class="view_box">
          <div class="title_box">
            <span class="title">视点</span>
            <span class="flex1 english">Viewpoints</span>
            <p class="btn" @click="goPage('/partyBuilding',5)">查看更多</p>
          </div>
          <div class="view_list">
            <p class="view_item" v-for="item in gylist" :key='item.id' @click="partyBuildingDetails(item)">
              {{item.partyTitle}}
            </p>
          </div>
        </div>
        <div class="flex1 new_box">
          <div class="title_box">
            <span class="title">新闻中心</span>
            <span class="flex1 english">Newsroom</span>
            <p class="btn" @click="goPage('/dynamic',3)">查看更多</p>
          </div>
          <ul class="new_list">
            <li class="new_item flex_box" v-for="item in newList" @click="gonewDetails(item)" :key='item.id'>
              <el-image :src="baseUrlImg+'/image?fileName='+item.photo" fit='fill' class="img" style="width: 220px; height: 150px"></el-image>
              <div class="flex1">
                <p class="time">
                  <span>{{item.newsType==1?'动态':'荣誉'}}</span>
                  <el-divider direction="vertical"></el-divider>
                  <span>{{item.createTime}}</span>
                </p>
                <h3 class="title">{{item.newsTitle}}</h3>
                <p class="desc" v-html="item.subtitle"></p>
              </div>
              <span class="iconfont icon-xiangyoujiantou"></span>
            </li>
          </ul>
        </div>
      </div>
      <div class="professionals">
        <div class="title_box">
          <span class="title">专业人员</span>
          <span class="flex1 english">Professionals</span>
          <p class="btn" @click="goprofessionalList">查看更多</p>
        </div>
        <div class="flex_box professionals_box">
          <div class="professionals_swiper">
            <el-carousel height='300px'>
              <el-carousel-item v-for="item in professionalPic" :key="item.id">
                <el-image :src="baseUrlImg+'/image?fileName='+item.photo" fit='contain' class="img" style="width: 100%; height: 300px" @click="goProDetails(item.id)"></el-image>
              </el-carousel-item>
            </el-carousel>
          </div>
          <el-form class="form_box" :inline='true'>
            <el-form-item>
              <el-select v-model="params.position" placeholder="按职位查找" class="ipt">
                <el-option
                  v-for="item in postList"
                  :key="item.dict_value"
                  :label="item.dict_label"
                  :value="item.dict_value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select v-model="params.serviceId" placeholder="按业务领域查找" class="ipt">
                <el-option
                  v-for="item in serveList"
                  :key="item.id"
                  :label="item.service_name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-input v-model="params.name" placeholder="按律师姓名查找" class="ipt"></el-input>
            </el-form-item>
            <el-form-item class="subbtn">
              <p @click="goprofessionalList">提 交</p>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="map_box" v-if="this.addressList.length>0">
      <h2><span>办公地址</span></h2>
      <div class="address_list">
        <div class="address_inner">
          <ul class="address_inner_box" :style="'left:'+(-addressIndexLeft*200)+'px'">
            <li v-for="(item,index) in addressList" :class="index==addressIndex?'cur':''" :key="index" @click="addressChange(index)">
              {{item.name}}
            </li>
          </ul>
        </div>
        <span class="iconfont icon-left-square" @click="addressLeftChange('prev')" v-if="addressList.length>3"></span>
        <span class="iconfont icon-right-square" @click="addressLeftChange('next')" v-if="addressList.length>3"></span>
      </div>
      <div class="map_con">
        <div class="address_box">
          <!-- <span class="iconfont icon-weizhi"></span> -->
          <p class="address_name">{{this.addressList[this.addressIndex].name}}</p>
          <p class="address">地址：{{this.addressList[this.addressIndex].address}}</p>
          <p class="address">电话：{{this.addressList[this.addressIndex].phone}}</p>
          <p class="address">邮箱：{{this.addressList[this.addressIndex].email}}</p>
        </div>
        <div id="mapContainer"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      newList:[], // 新闻列表
      postList:[], // 职位列表
      gylist:[], // 公益列表
      addressIndex:0,// 地址下标
      addressList:[],// 地址列表
      addressIndexLeft:0,// 地址列表向左滚动的下标
      map:'',// 地图实例
      bannerList:[], // 轮播图
      serveList:[],//业务领域
      params:{
        position:'',
        serviceId:'',
        name:'',
      },// 专业人员输入框
      professionalPic:[],
    }
  },
  mounted(){
    this.getNewsListForHome()
    this.getPostList()
    this.getServeList()
    this.getPartyBuildingByPage()
    this.getAddressList()
    this.getBanners()
    this.getProfessionalPicRandom()
  },
  methods: {
    // 初始化地图
    getAddressList() {
      this.$api.getCoordinates().then(res=>{
        this.addressList = res.data
        if (this.addressList.length == 0) return
        this.$nextTick(()=>{
          document.querySelector('.address_inner_box').style.width = this.addressList.length*220+'px'
        })
        this.initMap()
      })
    },
    initMap() {
      let coordinates = this.addressList[this.addressIndex].coordinates
      coordinates = coordinates ? JSON.parse(coordinates) : ''
      if (coordinates) {
        this.map && this.map.destroy();
        this.$nextTick(()=>{
          this.map = new AMap.Map('mapContainer', {
            zoom:9,//级别
            center: [coordinates.lng, coordinates.lat],//中心点坐标
          });
          var marker = new AMap.Marker({
            icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
            position: [coordinates.lng, coordinates.lat],
            anchor:'bottom-center'
          });
          this.map.add(marker)
        })
      }
    },
    addressChange(idx) {
      this.addressIndex = idx
      this.initMap()
    },
    addressLeftChange(type) {
      if (type == 'prev' && this.addressIndexLeft>0) {
        this.addressIndexLeft--
      } else if (type == 'next' && this.addressIndexLeft<this.addressList.length-1) {
        this.addressIndexLeft++
      }
    },
    // 获取轮播图列表
    getBanners() {
      this.$api.getBanners().then(res=>{
        this.bannerList = res.data
      })
    },
    // 职位列表
    getPostList() {
      this.$api.getAllPosition().then(res=>{
        this.postList = res.data
      })
    },
    // 业务领域列表
    getServeList() {
      this.$api.getAllServiceArea().then(res=>{
        this.serveList = res.data
      })
    },
    // 新闻列表
    getNewsListForHome() {
      this.$api.getNewsListForHome().then(res=>{
        this.newList = res.data
      })
    },
    // 获取泽汇党建列表
    getPartyBuildingByPage() {
      this.$api.getPartyBuildingByPage({pageNum:1,pageSize:10}).then(res=>{
        this.gylist = res.rows
      })
    },
    // 获取专业人员图片
    getProfessionalPicRandom() {
      this.$api.getProfessionalPicRandom({num:3}).then(res=>{
        this.professionalPic = res.data.picList
      })
    },
    // 社会责任详情
    partyBuildingDetails(item) {
      this.$router.push({
        path:'/partyBuildingDetails/'+item.id,
        query:{type:5}
      })
    },
    // 新闻详情
    gonewDetails(item) {
      this.$router.push({
        path:'/newDetails/'+item.id,
        query:{type:3}
      })
    },
    // 专业人员查询
    goprofessionalList() {
      this.$router.push({
        path:'/professionalList',
        query:{
          position:this.params.position,
          serviceId:this.params.serviceId,
          name:this.params.name,
          type:1
        }
      })
    },
    goProDetails(id) {
      this.$router.push({
        path:'/professionalDetails/'+id,
        query:{
          type:1
        }
      })
    },
    // 点击更多打开社会责任，新闻中心列表
    goPage(path,type) {
      this.$router.push({
        path:path,
        query:{
          type:type
        }
      })
    }
  }
}
</script>
<style>
  .desc img{
    max-width: 100%;
  }
</style>
<style scoped lang='stylus'>
@import '~@/assets/stylus/variable'
  .banner
    width: 100%;
  .main_content
    width: 1300px;
    margin:50px auto 0;
    .view_box
      width: 400px;
      margin-right 80px
      .view_list
        padding 20px
        height: 640px
        box-shadow 0 0 8px 4px rgba(0,0,0,.1)
        box-sizing border-box
        background #fff
        .view_item
          line-height: 50px
          font-size 16px
          color #000
          padding-left 20px
          position relative
          border-bottom: 1px dashed #bbb
          text-overflow: ellipsis
          overflow hidden
          white-space nowrap
          cursor pointer
          &:hover
            color $theme-color
          &:before
            display: block
            content: ''
            position: absolute
            left: 0
            top 50%
            transform: translateY(-50%)
            width: 6px
            height: 6px
            background: $theme-color
    .new_item
      padding 30px 20px 30px 0
      border-bottom: 1px dashed #bbb
      cursor pointer
      &:hover
        background: #ddd
        padding-left 20px
        .title
          color $theme-color
        .iconfont
          background: $theme-color
          border-color $theme-color
          color #fff
      .img
        margin-right 20px
      .time
        font-size 12px
        color #838383
      .title
        font-size 20px
        color #333333
        margin 15px 0
        text-overflow: ellipsis
        overflow: hidden
        white-space: nowrap
      .desc
        font-size 16px
        color #666666
        line-height: 30px
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      .iconfont
        width: 40px
        height: 40px
        border-radius 50%
        border 1px solid #bbb
        display: flex
        align-items: center
        justify-content: center
        margin-left: 30px
        font-size 20px
        color #999
    .professionals
      margin-top: 80px
      .professionals_box
        width: 100%
        margin 0 auto
      .professionals_swiper
        width: 400px
        .img
          cursor pointer
      .form_box
        width 900px
        margin-left: 50px
        .el-form-item
          width 47%
      .ipt
        width: 400px
        margin-bottom 20px
      .subbtn
        p
          width: 220px
          height: 40px
          text-align center
          line-height 40px
          background: $theme-color
          color #fff
          font-size 21px
          cursor pointer
    .title_box
      display: flex;
      align-items: flex-end;
      border-bottom 1px dashed #bbb
      padding-bottom 15px
      position: relative
      margin-bottom: 40px
      &::after
        display: block
        content: ''
        position: absolute
        left: 0
        bottom 0
        width: 220px
        height: 1px
        border-bottom 1px dashed red
      .title
        font-size 32px
      .english
        font-size 20px
        color #7e7e7e
      .btn
        width: 150px
        height: 50px
        text-align: center
        line-height: 50px
        border 1px solid #bbb
        font-size 16px
        color #7e7e7e
        cursor pointer
  .address_list
    width 1200px
    height: 40px
    margin 0 auto 30px
    padding-right: 80px
    box-sizing: border-box
    position relative
    .address_inner
      width: 100%
      height: 40px
      overflow: hidden
      position relative
      .address_inner_box
        display: flex
        align-items: center
        flex-wrap: nowrap
        overflow: hidden
        position absolute
        left 0
        top 0
    li
      width: 200px
      padding 0 10px
      line-height: 40px
      background: #729fd7
      color #fff
      font-size 14px
      margin-right: 20px
      text-align: center
      cursor pointer
      flex-shrink: 0
      box-sizing border-box
      text-overflow: ellipsis
      overflow: hidden
      white-space nowrap
      &:hover
        background: $theme-color
      &.cur
        background: $theme-color
    .icon-right-square
      font-size: 30px
      position absolute
      right 0
      top 5px
      color #fff
      cursor pointer
    .icon-left-square
      font-size 30px
      position absolute
      right 30px
      top 5px
      color #fff
      cursor pointer
  .map_box
    width: 100%
    background: url('~@/assets/images/bg2.png') no-repeat center center
    background-size cover
    padding 50px 0
    box-sizing border-box
    margin-top 50px
    h2
      font-size: 30px
      text-align: center
      margin-bottom 36px
      color #fff
      span
        padding-bottom 5px
        border-bottom 3px solid $theme-color
    .map_con
      width: 1200px
      margin 0 auto
      background: #fff
      border-radius 4px
      padding 10px
      box-sizing border-box
      display: flex
    #mapContainer
      height: 320px
      flex 2
      min-width: 0
    .address_box
      flex 1
      min-width: 0
      padding 40px 20px 20px
      .iconfont
        font-size 40px
      .address
        line-height: 20px
        font-size 14px
        margin-top 10px
      .address_name
        font-size: 20px
        color $theme-color
        margin-bottom 20px
</style>