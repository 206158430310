import {Get, Post} from './request'
export default {
	// --------------专业人员----------------
	// 根据分页获取专业人员列表
	getProfessionalByPage(param) {
		return Post(`/api/professional/getProfessionalByPage`,param)
	},
	// 获取全部职位
	getAllPosition(param) {
		return Post(`/api/professional/getAllPosition`,param)
	},
	// 获取专业人员详情
	getProfessionalDetail(param) {
		return Post(`/api/professional/getProfessionalDetail`,param)
	},
	// --------------党建----------------
	// 根据分页获取党建列表
	getPartyBuildingByPage(param) {
		return Post(`/api/partyBuilding/getPartyBuildingByPage`,param)
	},
	// 获取党建详情
	getPartyBuildingDetail(param) {
		return Post(`/api/partyBuilding/getPartyBuildingDetail`,param)
	},
	// 首页获取专业人员图片
	getProfessionalPicRandom(param) {
		return Post(`/api/professional/getProfessionalPicRandom`,param)
	},
	// --------------基础信息----------------
	// 获取关于我们
	getAboutUs(param) {
		return Post(`/api/info/getAboutUs`,param)
	},
	// 获取联系我们
	getCallUs(param) {
		return Post(`/api/info/getCallUs`,param)
	},
	// 获取加入我们
	getJoinUs(param) {
		return Post(`/api/info/getJoinUs`,param)
	},
	// 获取职业理念
	getZhiyelinian(param) {
		return Post(`/api/info/getZhiyelinian`,param)
	},
	// 获取首页轮播图
	getBanners(param) {
		return Post(`/api/info/getBanners`,param)
	},
	// 获取首页地图位置信息
	getCoordinates(param) {
		return Post(`/api/info/getCoordinates`,param)
	},
	// --------------新闻中心----------------
	// 获取新闻详情
	getNewsDetail(param) {
		return Post(`/api/news/getNewsDetail`,param)
	},
	// 根据分页获取新闻列表
	getNewsListByPage(param) {
		return Post(`/api/news/getNewsListByPage`,param)
	},
	// 获取首页展示的新闻中心列表
	getNewsListForHome(param) {
		return Post(`/api/news/getNewsListForHome`,param)
	},
	// --------------服务领域----------------
	// 获取全部服务领域
	getAllServiceArea(param) {
		return Post(`/api/serviceArea/getAllServiceArea`,param)
	},
	// 获取服务领域详情
	getServiceAreaDetail(param) {
		return Post(`/api/serviceArea/getServiceAreaDetail`,param)
	},
	// --------------社会责任----------------
	// 根据分页获取公益列表
	getGongyiByPage(param) {
		return Post(`/api/social/getGongyiByPage`,param)
	},
	// 根据分页获取亲情律师列表
	getQinqinglsByPage(param) {
		return Post(`/api/social/getQinqinglsByPage`,param)
	},
	// 获取详情
	getSocialDetail(param) {
		return Post(`/api/social/getSocialDetail`,param)
	},
	// 根据分页获取一社区一顾问列表
	getYiShequYiGuWenByPage(param) {
		return Post(`/api/social/getYiShequYiGuWenByPage`,param)
	},
	// 根据分页获取真心法律调节中心列表
	getZhenxinfltjzxByPage(param) {
		return Post(`/api/social/getZhenxinfltjzxByPage`,param)
	},
	// --------------荣誉----------------
	// 根据分页获取荣誉列表
	getHonorByPage(param) {
		return Post(`/api/honor/getHonorByPage`,param)
	},
	// 获取荣誉详情
	getHonorDetail(param) {
		return Post(`/api/honor/getHonorDetail`,param)
	}
}