<template>
  <div class="index_top">
    <img src="@/assets/images/logo.png" alt="" class="logo" @click="goHome">
    <div class="flex1">
      <div class="nav_box flex_row_end">
        <span class="nav_item" @click="goHome">首页</span>
        <span class="line"></span>
        <span class="nav_item" :class="modeActive==item.type?'active':''" v-for="(item,index) in navList" :key='index' @click="goPage(item)">{{item.name}}</span>
      </div>
      <div class="modular_box flex_row_end">
        <div class="modular_item" :class="modeActive==index?'active':''" v-for="(item,index) in modeList" :key='index'>
          <span class="title" @click="goPage(item)">{{item.name}}</span>
          <ul class="children_box" v-if="item.children">
            <li v-for="item1 in item.children" @click="goPage(item1)">{{item1.name}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
  export default {
    data () {
      return {
        navList:[
          {
            name:'泽汇党建',
            url:'/partyBuilding',
            type:'5'
          },
          // {
          //   name:'文明创建',
          //   url:'/civilization',
          //   type:'6'
          // },
          {
            name:'律所荣誉',
            url:'/lawFirmHonors',
            type:'6'
          },
          {
            name:'加入我们',
            url:'/addUs',
            type:'7'
          },
          {
            name:'联系我们',
            url:'/contactUs',
            type:'8'
          }
        ],
        modeIndex:0,
        modeList:[
          {
            name:'青大泽汇',
            url:'/about',
            type:'0',
            children:[
              {name:'关于我们',url:'/about',type:'0'},
              // {name:'职业理念',url:'/professionIdea',type:'0'}
            ]
          },
          {
            name:'专业人员',
            url:'/professionalList',
            type:'1'
          },
          {
            name:'服务领域',
            url:'/serviceList',
            type:'2'
          },
          {
            name:'新闻中心',
            url:'/dynamic',
            type:'3',
            children:[
              {name:'动态',url:'/dynamic',type:'3'},
              {name:'荣誉',url:'/honor',type:'3'}
            ]
          },
          {
            name:'社会责任',
            url:'/publicWelfare',
            type:'4',
            children:[
              {name:'公益',url:'/publicWelfare',type:'4'},
              {name:'亲情律师',url:'/family',type:'4'},
              {name:'真心法律调解中心',url:'/mediationCenter',type:'4'},
              {name:'社区——顾问',url:'/community',type:'4'}
            ]
          }
        ]
      }
    },
    computed:{
      ...mapGetters(['modeActive'])
    },
    methods: {
      goHome() {
        this.$store.commit('set_modeActive',-1)
        this.$router.push('/')
      },
      goPage(item) {
        if (item.url == this.$route.path) return
        this.$router.push({
          path:item.url,
          query:{
            type:item.type
          }
        })
      }
    }
  }
</script>

<style scoped lang='stylus'>
@import '~@/assets/stylus/variable'
.index_top{
  width: 1300px;
  margin 0 auto;
  padding:20px 0 0
  box-sizing: border-box
  display: flex;
  align-items: flex-end;
}
.logo{
  width: 300px;
  margin-bottom: 20px;
  cursor pointer
}
.nav_box{
  font-size:16px;
  color:#000;
  font-weight:bold;
}
.nav_item{
  margin-left: 20px;
  cursor pointer
  line-height: 30px
}
.nav_item.active{
  color:$theme-color;
}
.line{
  width: 1px;
  height: 20px;
  background: #000
  margin:0 20px 0 40px;
}
.modular_box{
  font-size:22px;
  color:#363636;
  font-weight:bold;
}
.modular_item{
  margin-left: 50px;
  padding 20px 0;
  position relative
  cursor pointer
}
.modular_item:hover .title{
  color:$theme-color;
}
.modular_item:hover .children_box{
  display: block
}
.modular_item::after{
  display: block
  content: ''
  position absolute
  left: 50%
  bottom 0
  transform: translateX(-50%)
  width: 100px
  height: 2px
}
.active::after{
  background: $theme-color
}
.children_box{
  position absolute
  left 50%
  top 61px
  padding 10px
  box-shadow 0 0 8px 4px rgba(0,0,0,.1)
  background: rgba(255,255,255,.8)
  z-index: 9
  width 130px
  transform: translateX(-50%)
  display: none;
  animation: myfirst 0.3s linear;
  overflow-y: hidden;
}
.children_box li{
  font-size 16px
  line-height: 40px
  text-align center
}
.children_box li:hover{
  color:$theme-color
}
@keyframes myfirst{
  from {opacity : 0;}
  to {opacity: 1;}
}
</style>