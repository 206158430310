import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/main',
    name: 'main',
    component: () => import(/* webpackChunkName: "main" */ '../views/main.vue'),
    children:[
      {
        path: '/about',
        name: 'About',
        meta:{title:'关于我们'},
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
      },
      {
        path: '/professionIdea',
        name: 'professionIdea',
        meta:{title:'职业理念'},
        component: () => import(/* webpackChunkName: "professionIdea" */ '../views/professionIdea.vue')
      },
      {
        path: '/professionalList',
        name: 'professionalList',
        meta:{title:'专业人员'},
        component: () => import(/* webpackChunkName: "professionalList" */ '../views/professionalList.vue')
      },
      {
        path: '/professionalDetails/:id',
        name: 'professionalDetails',
        meta:{title:'专业人员详情'},
        component: () => import(/* webpackChunkName: "professionalDetails" */ '../views/professionalDetails.vue')
      },
      {
        path: '/serviceList',
        name: 'serviceList',
        meta:{type:2,title:'服务领域'},
        component: () => import(/* webpackChunkName: "serviceList" */ '../views/serviceList.vue')
      },
      {
        path: '/serviceDetails/:id',
        name: 'serviceDetails',
        meta:{type:2,title:'服务领域详情'},
        component: () => import(/* webpackChunkName: "service" */ '../views/serviceDetails.vue')
      },
      {
        path: '/dynamic',
        name: 'dynamic',
        meta:{title:'动态'},
        component: () => import(/* webpackChunkName: "dynamic" */ '../views/dynamic.vue')
      },
      {
        path: '/honor',
        name: 'honor',
        meta:{title:'荣誉'},
        component: () => import(/* webpackChunkName: "honor" */ '../views/honor.vue')
      },
      {
        path: '/publicWelfare',
        name: 'publicWelfare',
        meta:{title:'公益'},
        component: () => import(/* webpackChunkName: "publicWelfare" */ '../views/publicWelfare.vue')
      },
      {
        path: '/family',
        name: 'family',
        meta:{title:'亲情'},
        component: () => import(/* webpackChunkName: "family" */ '../views/family.vue')
      },
      {
        path: '/mediationCenter',
        name: 'mediationCenter',
        meta:{title:'真心法律调解中心'},
        component: () => import(/* webpackChunkName: "mediationCenter" */ '../views/mediationCenter.vue')
      },
      {
        path: '/community',
        name: 'community',
        meta:{title:'社区顾问'},
        component: () => import(/* webpackChunkName: "community" */ '../views/community.vue')
      },
      {
        path: '/partyBuilding',
        name: 'partyBuilding',
        meta:{type:5,title:'泽汇党建'},
        component: () => import(/* webpackChunkName: "partyBuilding" */ '../views/partyBuilding.vue')
      },
      // {
      //   path: '/civilization',
      //   name: 'civilization',
      //   meta:{type:6,title:'文明创建'},
      //   component: () => import(/* webpackChunkName: "civilization" */ '../views/civilization.vue')
      // },
      {
        path: '/lawFirmHonors',
        name: 'lawFirmHonors',
        meta:{type:6,title:'律所荣誉'},
        component: () => import(/* webpackChunkName: "lawFirmHonors" */ '../views/lawFirmHonors.vue')
      },
      {
        path: '/addUs',
        name: 'addUs',
        meta:{type:7,title:'加入我们'},
        component: () => import(/* webpackChunkName: "addUs" */ '../views/addUs.vue')
      },
      {
        path: '/contactUs',
        name: 'contactUs',
        meta:{type:8,title:'联系我们'},
        component: () => import(/* webpackChunkName: "contactUs" */ '../views/contactUs.vue')
      },
      {
        path: '/newDetails/:id',
        name: 'newDetails',
        meta:{type:3,title:'新闻详情'},
        component: () => import(/* webpackChunkName: "newDetails" */ '../views/newDetails.vue')
      },
      {
        path: '/partyBuildingDetails/:id',
        name: 'partyBuildingDetails',
        meta:{type:5,title:'党建详情'},
        component: () => import(/* webpackChunkName: "partyBuildingDetails" */ '../views/partyBuildingDetails.vue')
      },
      {
        path: '/socialDetails/:id',
        name: 'socialDetails',
        meta:{type:4,title:'社会责任详情'},
        component: () => import(/* webpackChunkName: "socialDetails" */ '../views/socialDetails.vue')
      },
      {
        path: '/lawFirmHonorsDetails/:id',
        name: 'lawFirmHonorsDetails',
        meta:{type:6,title:'荣誉详情'},
        component: () => import(/* webpackChunkName: "lawFirmHonorsDetails" */ '../views/lawFirmHonorsDetails.vue')
      },
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
