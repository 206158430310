<template>
  <div class="pagination">
    <el-pagination
      layout="prev, pager, next"
      @current-change='currentChange'
      :page-size='pageSize'
      :total="total">
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    total:{
      type:Number,
      default:0
    },
    pageSize:{
      type:Number,
      default:10
    }
  },
  methods:{
    currentChange(val) {
      this.$emit('currentChange',val)
    }
  }
}
</script>

<style lang='stylus' scoped>
.pagination
  padding 20px 0
  text-align center
/deep/ .el-pager li
  font-size 18px
/deep/ .el-pagination .btn-next .el-icon, /deep/ .el-pagination .btn-prev .el-icon
  font-size 18px
</style>