<!--
 * @Description: 一入编程深似海,佛祖保佑,永无bug
 * @Author: 独孤求败
 * @Date: 2021-12-06 14:00:54
 * @LastEditTime: 2021-12-07 09:56:10
 * @LastEditors: 独孤求败
 * @Reference: 纵横江湖三十馀载，杀尽仇寇，败尽英雄，天下更无抗手，无可奈何，惟隐居深谷，以雕为友。 呜呼！生平求一敌手而不可得，诚寂寥难堪也！！！
-->
<template>
  <div id="app">
    <headers></headers>
    <router-view/>
    <footers></footers>
  </div>
</template>
<script>
import headers from '@/components/header.vue'
import footers from '@/components/footer.vue'
export default {
  components:{
    headers,
    footers
  },
  mounted() {
    // console.log(this.$route)
  }
}
</script>
<style lang="stylus">
@import '~@/assets/stylus/variable.styl'
@import '~@/assets/iconfont.css'
@import '~@/assets/stylus/style.min.css'
@import '~@/assets/stylus/bootstrap.min.css'
// @import '~@/assets/stylus/ry-ui.css'
#app
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  color $text-color
  // background: url('~@/assets/images/bg.jpg') no-repeat center center
  // background-size cover
.w1200
  width: 1200px
  margin 50px auto 0
table
  width: 100%
</style>
