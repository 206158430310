import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import qs from 'qs'
// import store from '@/store'
import router from '@/router'
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    return res
  },
  error => {
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service

/**
 * Post 请求方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function Post (url, data = {}) {
  return new Promise((resolve, reject) => {
    service({
      method: 'post',
      url: url,
      data: qs.stringify(data),
      // header: header
    }).then(response => {
      // 对接口错误码做处理
      resolve(response)
    }).catch(err => {
      reject(err)
    })
  })
}
/**
 * Get 请求方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function Get (url, data = {}) {
  return new Promise((resolve, reject) => {
    service.get(url, {
      params: data
    }).then(response => {
      resolve(response)
    }).catch(err => {
      reject(err)
    })
  })
}