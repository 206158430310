<template>
  <div class="header_box">
    <div class="header_inner flex_box" v-if="type==7">
      <div class="contactUs_info flex_column_center" v-if="list.length>0">
        <h1>加入我们</h1>
        <span>add us</span>
      </div>
    </div>
    <div class="header_inner flex_box" v-if="type==8">
      <div class="contactUs_info flex_column_center" v-if="list.length>0">
        <h1>联系我们</h1>
        <span>contact us</span>
      </div>
    </div>
    <div class="header_inner flex_row_end" v-else>
      <div class="social_info flex_column_center" v-if="list.length>0">
        <h1 v-if="type!=1">{{list[type].title}}</h1>
        <span 
          v-for="(item,index) in list[type].children" 
          :class="($route.path==item.url) || (item.id && professionId==item.id)?'active':''" 
          :key='index' 
          @click="goPage(item)">{{item.name}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list:[
        {
          title:'青大泽汇概况',
          children:[
            {name:'关于我们',url:'/about'},
            // {name:'职业理念',url:'/professionIdea'}
          ]
        },
        {
          title:'专业人员',
          children:[]
        },
        {
          title:'服务领域',
          children:[]
        },
        {
          title:'新闻中心',
          children:[
            {name:'动态',url:'/dynamic'},
            {name:'荣誉',url:'/honor'}
          ]
        },
        {
          title:'社会责任',
          children:[
            {name:'公益',url:'/publicWelfare'},
            {name:'亲情律师',url:'/family'},
            {name:'真心法律调解中心',url:'/mediationCenter'},
            { name:'社区——顾问',url:'/community'}
          ]
        },
        {
          title:'泽汇党建',
          children:[]
        },
        {
          title:'律所荣誉',
          children:[]
        },
        {
          title:'加入我们',
          children:[]
        },
        {
          title:'联系我们',
          children:[]
        },
      ],
      professionId:''
    }
  },
  props:{
    type:{
      type:String,
      default:0
    }
  },
  watch:{
    type() {
      console.log(123)
      if (this.type == 1) {
        this.$api.getCoordinates().then(res=>{
          let data = res.data
          this.list[this.type].children = []
          data.forEach(element => {
            this.list[this.type].children.push({
              name:element.name,
              id:element.id
            })
          });
        })
      } else {
        this.professionId = ''
      }
    }
  },
  methods:{
    goPage(item) {
      if (item.url) {
        this.$router.push({
          path:item.url,
          query:{
            type:this.type
          }
        })
      } else {
        this.professionId = item.id
        this.$emit('peopleChange',item.id)
      }
    }
  }
}
</script>

<style lang='stylus' scoped>
  .header_box
    height: 400px
    background: url('~@/assets/images/bg1.jpg') no-repeat center center
    background-size cover
    .header_inner
      width: 1200px
      margin 0 auto
      height: 100%
      .social_info
        width: 480px
        height: 100%
        background: rgba(255,255,255,.3)
        color #fff
        font-size 20px
        h1
          font-size 36px
          margin-bottom 40px
        span
          line-height: 36px
          cursor pointer
          margin-bottom 10px
          &.active
            border-bottom 1px solid #fff
      .contactUs_info
        width: 400px
        height: 100%
        color #fff
        h1
          font-size 50px
          margin-bottom: 10px
        span
          font-size 30px
      .right_box
        font-size 40px
        color #fff
        margin-left: 100px
        span
          margin 0 20px
          line-height: 60px
</style>