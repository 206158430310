<template>
  <div class="footers">
    <!-- <img src="@/assets/images/foot.png" alt="" class="foot_logo"> -->
    <div class="w1200 flex_row_between">
      <div class="foot_nav">
        <dl v-for="item in list">
          <dt>{{item.title}}</dt>
          <dd>
            <ul>
              <li v-for="item1 in item.children" @click="goPage(item1)">{{item1.name}}</li>
            </ul>
          </dd>
        </dl>
      </div>
      <img src="@/assets/images/ewm.jpg" alt="" class="ewm">
    </div>
    
    <!-- <ul class="foot_nav">
      <li v-for="(item,index) in list" :key='index' @click="goPage(item)">{{item.name}}</li>
    </ul> -->
  </div>
</template>

<script>
export default{
  data(){
    return{
      list:[
        {
          title:'青大泽汇',
          children:[
            {name:'关于我们',url:'/about',type:'0'},
            {name:'职业理念',url:'/professionIdea',type:'0'}
          ]
        },
        {
          title:'专业人员',
          children:[
            {name:'专业人员',url:'/professionalList',type:'1'},
          ]
        },
        {
          title:'服务领域',
          children:[
            {name:'服务领域',url:'/serviceList',type:'2'},
          ]
        },
        {
          title:'新闻中心',
          children:[
            {name:'动态',url:'/dynamic',type:'3'},
            {name:'荣誉',url:'/honor',type:'3'}
          ]
        },
        {
          title:'社会责任',
          children:[
            {name:'公益',url:'/publicWelfare',type:'4'},
            {name:'亲情律师',url:'/family',type:'4'},
            {name:'真心法律调解中心',url:'/mediationCenter',type:'4'},
            {name:'社区——顾问',url:'/community',type:'4'}
          ]
        },
        {
          title:'泽汇党建',
          children:[
            {name:'泽汇党建',url:'/partyBuilding',type:'5'},
          ]
        },
        {
          title:'律所荣誉',
          children:[
            {name:'律所荣誉',url:'/lawFirmHonors',type:'6'},
          ]
        },
        {
          title:'加入我们',
          children:[
            {name:'加入我们',url:'/addUs',type:'7'},
          ]
        },
        {
          title:'联系我们',
          children:[
            {name:'联系我们',url:'/contactUs',type:'8'},
          ]
        },
      ],
      // list:[
      //   {
      //     name:'首页',
      //     url:'/',
      //     type:'-1'
      //   },
      //   {
      //     name:'青大泽汇',
      //     url:'/about',
      //     type:'0',
      //     children:[]
      //   },
      //   {
      //     name:'专业人员',
      //     url:'/professionalList',
      //     type:'1'
      //   },
      //   {
      //     name:'服务领域',
      //     url:'/serviceList',
      //     type:'2'
      //   },
      //   {
      //     name:'新闻中心',
      //     url:'/dynamic',
      //     type:'3'
      //   },
      //   {
      //     name:'社会责任',
      //     url:'/publicWelfare',
      //     type:'4'
      //   },
      //   {
      //     name:'泽汇党建',
      //     url:'/partyBuilding',
      //     type:'5'
      //   },
      //   {
      //     name:'泽汇荣誉',
      //     url:'/lawFirmHonors',
      //     type:'6'
      //   },
      //   {
      //     name:'加入我们',
      //     url:'/addUs',
      //     type:'7'
      //   },
      //   {
      //     name:'联系我们',
      //     url:'/contactUs',
      //     type:'8'
      //   }
      // ]
    }
  },
  methods:{
    goPage(item) {
      this.$router.push({
        path:item.url,
        query:{
          type:item.type
        }
      })
    }
  }
}
</script>

<style scoped lang="stylus">
@import '~@/assets/stylus/variable'
.footers{
  padding: 0 0 40px;
  text-align: center;
}
// .foot_logo{
//   margin-bottom:46px;
// }
dl{
  font-size:16px;
  line-height: 24px;
  margin: 0 20px
}
dt{
  font-size:16px;
  margin-bottom 10px;
  font-weight bold
  cursor pointer
  text-align left
}
dd{
  font-size 14px
}
dd li{
  cursor pointer;
  text-align left
}
dt:hover,dd li:hover{
  color:$theme-color;
  font-weight bold
}
.foot_nav{
  display: flex;
  justify-content: center;
  align-content: center;
}
.ewm{
  width 150px
}
// .foot_nav li{
//   margin:0 15px;
//   color:$theme-color;
//   font-size:30px;
//   cursor: pointer;
// }
</style>